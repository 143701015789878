
class ContactForm {

    static onChange() {

        $( '.fg-contact__input input, .fg-contact__textarea textarea' ).keyup(function() {
            const thisLabel = $( `label[for= ${$(this)[0].id}]` );
            if( $(this).val().length > 0 ) {
                thisLabel.show();
                $(this).addClass('active');
            } else {
                thisLabel.hide();
                $(this).removeClass('active');
            }
        });
    }

    static validation() {

        $( '#contactForm' ).submit(function( event ) {

            const name = $( '#nameSurname' ),
                email = $( '#emailPhone' ),
                message = $( '#message' );

            if ( $( name ).val().length === 0
                || $( email ).val().length === 0
                || $( message ).val().length === 0) {
                event.preventDefault();
            } else {
                $( '.fg-contact__step-1' ).hide();
                return;
            }

            $.each( { name, email, message }, function( i, l ){
                const spanError = `#${$( this )[0].id}Error`,
                    iconError = `#${$( this )[0].id}ErrorIcon`;

                if ( $( this ).val().length === 0 ) {
                    $(spanError).show();
                    $(iconError).show();
                } else {
                    $(spanError).hide();
                    $(iconError).hide();
                }
            });
        });
    }
}

$(document).ready(function () {
    ContactForm.onChange();
    ContactForm.validation();
});


const cookiesToggler = () => {
  const cookiesBar = document.getElementById('cookiesBar'),
      cookiesBarBtn = document.getElementById('cookiesBarBtn');

  if(cookiesBarBtn != null) {
      cookiesBarBtn.addEventListener('click', function(e) {
          e.preventDefault();
          cookiesBar.classList.toggle('fg-cookies--approve');
          $.cookie('hideCookieBar', 1, {expires: 28});
      });
  }
};


$( document ).ready(function() {
    objectFitImages();
    cookiesToggler();

    let toggledArea = document.getElementById('toggledArea');
    let toggledBtn = document.getElementById('toggledBtn');

    if (toggledBtn && toggledArea) {

        if (toggledArea.offsetHeight > 320) {
            toggledBtn.style.display = 'block';
            toggledArea.classList.add('closed');
        }

        toggledBtn.addEventListener('click', function (e) {
            e.preventDefault();
            toggledBtn.classList.toggle('open');
            toggledArea.classList.toggle('open');
        });
    }

    let newsToggle = $('#otherNewsToggle');
    let otherNews = $('#otherNews');

    if ($(newsToggle).length > 0 && otherNews.length > 0) {
        $(newsToggle).click(function(e) {
            e.preventDefault();
            $(otherNews).fadeToggle();
        });
    }
});

class Modal {

    static oSkupine() {
        /*
        const modal = document.getElementById('modalWindow');
        const btn = document.getElementById('modalBtn');
        const span = document.getElementsByClassName('fg-modal__close')[0];

        btn.onclick = function() {
            modal.style.display = 'block';
        };

        span.onclick = function() {
            modal.style.display = 'none';
        };

        window.onclick = function(event) {
            if (event.target === modal) {
                modal.style.display = 'none';
            }
        };
        */
        $( '.fg-companies__container a' ).click(function( event ) {
            event.preventDefault();
            const modalId = $(this).attr('data-id');
            $(`#${modalId}`).css('display','block');

            $( '.fg-modal__close' ).click(function ( event ) {
                $(`#${modalId}`).css('display','none');
            });
            window.onclick = function(event) {
                if (event.target.id === modalId) {
                    $(`#${modalId}`).css('display','none');
                }
            };
        });
    }
}

$(document).ready(function(){
    Modal.oSkupine();
});


class SmoothScrolling {

    static init() {
        $('a[href*="#"]')
            .not('[href="#"]')
            .not('[href="#0"]')
            .click(function (event) {
                if (
                    location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '')
                    &&
                    location.hostname === this.hostname
                ) {
                    let target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                    if (target.length) {
                        event.preventDefault();
                        $('html, body').animate({
                            scrollTop: target.offset().top - 100
                        }, 1000, function () {
                            const $target = $(target);
                            if ($target.is(':focus')) {
                                return false;
                            } else {
                                $target.attr('tabindex', '-1');
                            }
                        });
                    }
                }
            });
    }

}

$(document).ready(function () {
    SmoothScrolling.init();
});



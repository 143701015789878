
class OwlCarousel {
    static header() {
        $('.fg-header-carousel').owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            nav: false,
            margin: 0,
            startPosition: 1,
            autoplay: true,
            smartSpeed: 750,
            animateOut: 'fadeOut',
        });

        $('.fg-header-carousel .owl-dots').removeClass('disabled');
    }

    static ourProjects() {
        $('.fg-our-projects-carousel').owlCarousel({
            items: 3,
            loop: true,
            dots: false,
            nav: true,
            margin: 50,
            responsive: {
                0: {
                    items: 1,
                    margin: 0,
                },
                992: {
                    items: 3,
                    margin: 50,
                }
            }
        });

        $('.fg-our-projects-carousel .owl-nav').removeClass('disabled');

        let iterator = 1;
        $.each($('.fg-our-projects-carousel .owl-item'), function(i, item) {
            $(item).addClass('owl-item--' + iterator);
            iterator % 3 === 0 ? iterator = 1 : iterator++;
        });
    }
}

$(document).ready(function(){
    OwlCarousel.header();
    OwlCarousel.ourProjects();
});


class LightSlider {
    static historyDesktop() {
        $('#lightSlider').lightSlider({
            gallery: true,
            item: 1,
            loop: true,
            slideMargin: 0,
            thumbItem: 4,
            thumbMargin: 25,
            onSliderLoad: function(el) {
                LightSlider.setSliderHeight($(el));

                el.lightGallery({
                    selector: '#lightSlider .lslide'
                });
            }
        });
    }
    static historySmDesktop() {
        $('#lightSlider').lightSlider({
            gallery: true,
            item: 1,
            loop: true,
            slideMargin: 0,
            thumbItem: 3,
            thumbMargin: 25,
            onSliderLoad: function(el) {
                LightSlider.setSliderHeight($(el));

                el.lightGallery({
                    selector: '#lightSlider .lslide'
                });
            }
        });
    }
    /**
     * Set slider height by slider wrapper width
     *
     * @param {jQuery} $container
     */
    static setSliderHeight($container) {
        var container = $container,
            sliderWrapper = container.parent(),
            height = sliderWrapper.width() * (2/3);

        container.height(height);
    }
}

$(document).ready(function(){

    if (window.matchMedia('(max-width: 1370px)').matches) {
        LightSlider.historySmDesktop();
    } else {
        LightSlider.historyDesktop();
    }
});

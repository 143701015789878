class FixedNavbar {

    static init() {
        window.onscroll = function() {theFn();};

        const navbar = document.getElementById('navbar'),
        fixed = navbar.offsetHeight;

        function theFn() {
            if (window.pageYOffset >= fixed) {
                navbar.classList.add('fg-navbar--fixed');
            } else {
                navbar.classList.remove('fg-navbar--fixed');
            }
        }
    }
}

$(document).ready(function () {
    FixedNavbar.init();
});
